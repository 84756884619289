var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-page-swiper',{staticClass:"home",class:{ mobile: _vm.isMobile },attrs:{"titleList":_vm.titleList}},[_vm._l((_vm.homeProducts),function(product,key){return _c('swiper-slide',{key:'product' + key,staticStyle:{"background-color":"#000000"}},[(
        _vm.isMobile &&
          (_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1])
      )?[(
          (_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).type === 'video'
        )?_c('v-video',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).url,"loop":""}}):_c('img',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).url,"alt":_vm.isCN ? product.cn_top_name : product.overseas_top_name},on:{"click":function($event){return _vm.learnMore(product)}}})]:[(
          (_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).type === 'video'
        )?_c('v-video',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).url,"loop":""}}):_c('img',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).url,"alt":_vm.isCN ? product.cn_top_name : product.overseas_top_name},on:{"click":function($event){return _vm.learnMore(product)}}})],(_vm.buttonText(product))?_c('div',{staticClass:"button",on:{"click":function($event){return _vm.onProductClick(product)}}},[_c('div',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.buttonText(product)))]),_c('img',{staticClass:"button-arrow",attrs:{"src":_vm.images.home_arrow}})]):_vm._e()],2)}),_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('v-video',{staticClass:"home-cv",attrs:{"src":_vm.oss.home_cv,"playback":_vm.isMobile ? _vm.oss.home_cv_playback_mobile : _vm.oss.home_cv_playback,"loop":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }