




































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { dispatch, ProductName, Product } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      time: moment() as moment.Moment
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      language: "language",
      isMobile: "isMobile",
      products: "products"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss",
      url: "url"
    }),
    ...mapGetters({
      homeProducts: "homeProducts",
      getStatus: "getStatus"
    }),
    titleList(): string[] {
      return [
        ...this.homeProducts.map(
          (p: Product) => p[this.isCN ? "cn_top_name" : "overseas_top_name"]
        ),
        "ABOUT US",
        "FOOTER"
      ];
    },
    end(): string {
      return this.isCN
        ? "2022-08-30T14:00:00+08:00"
        : "2022-09-01T23:00:00+08:00";
    }
  },
  methods: {
    onTimeChange(time: string) {
      if (!time) {
        window.location.reload();
      }
      this.time = moment();
    },
    buttonText(product: Product) {
      const status = this.getStatus(product.code);
      if (status === "jump") {
        return this.isCN ? product.cn_button : product.overseas_button;
      } else if (status === "restock") {
        return this.$t("preorder") + "";
      } else if (status === "GB") {
        return this.$t("preorder") + "";
      } else if (status === "inStock") {
        return this.$t("buy") + "";
      }
      return this.$t("learnMore") + "";
    },
    onProductClick(product: Product) {
      if (["restock", "GB", "inStock"].includes(this.getStatus(product.code))) {
        this.orderNow(product);
      } else {
        this.learnMore(product);
      }
    },
    learnMore(product: Product) {
      if (this.getStatus(product.code) === "jump") {
        window.open(
          this.isCN ? product.cn_url : product.overseas_url,
          "_blank"
        );
      } else {
        dispatch.learnMore(product.code);
      }
    },
    orderNow(product: Product) {
      dispatch.orderNow(product.code);
    },
    followUs(name?: ProductName) {
      dispatch.followUs(name);
    },
    goTask(id: number) {
      if (this.isMobile) {
        this.$router.push(this.$paths.dao + id + "/");
      } else {
        window.open(this.$paths.dao + id + "/", "_blank");
      }
    },
    open(url: string) {
      window.open(url, "_blank");
    },
    onR4Click() {
      this.isCN
        ? this.$router.push(this.$paths.cyberboardR4)
        : window.open(this.url.discord, "_blank");
    }
  }
});
